export const initState = {
  backImage: null,
  backSlider: false,
  contactForm: false,
  contactFormCurrent: 1,
  contactFormMessages: [],
  rightImage: null,
  hasBackImage: null,
  hiddenDown: false,
  isFooter: false,
  isMenu: false,
  jumboCount: 0,
  isMobile: false,
  previousTheme: 'white',
  sicgrid: 'left',
  sliderCounter: {},
  storedTheme: 'white',
  worksFilter: null,
  worksFiltersOpen: false,
  worksGrid: true,
}
