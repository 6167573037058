module.exports = [{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"49403653","webvisor":false,"trackHash":false},
    },{
      plugin: require('/vercel/path0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
